<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}" :initial-data="initialData">

        <div class="row col-12">
            <validated-input class="col-12" label="Place" name="Place" v-model="model.place"
                             :rules="rules.place" :disabled="loading"/>
        </div>

        <div class="row col-12">
            <validated-textarea class="col-12" label="Address" v-model="model.address"
                                :rules="rules.address" :disabled="loading"/>

        </div>

        <div class="row col-12">
            <validated-input class="col-6" label="Phone Number" name="Phone number" v-model="model.phone_number"
                             :rules="rules.phone_number" :disabled="loading" type="number"/>

            <validated-input class="col-6" label="Email" v-model="model.email" type="email"
                             :rules="rules.email" :disabled="loading"/>
        </div>

        <div class="row col-12">
            <validated-input class="col-6" label="Latitude" name="Latitude" v-model="model.latitude"
                             :rules="rules.phone_number" :disabled="loading"/>
            <validated-input class="col-6" label="Longitude" name="Longitude" v-model="model.longitude"
                             :rules="rules.phone_number" :disabled="loading"/>
        </div>

        <div class="row col-12">
            <validated-input class="col-12" label="Alternative Phone Number" name="Alternative phone number"
                             v-model="model.alternative_phone_number"
                             :rules="rules.alternative_phone_number" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'EditAddress',
    computed : { ...mapGetters(['currentUser']) },
    props    : { initialData : { type : Object } },
    data () {
        return {
            addUrl : urls.admin.address.addEdit,
            rules  : {
                place : {
                    required : true
                },
                address : {
                    required : true
                },
                phone_number : {
                    required : true
                },
                alternative_phone_number : {
                    required : false
                },
                email : {
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Edited Address..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
