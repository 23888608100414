<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'ADDRESS'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.addressAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #alternative_phone_number="{rowData}">
                <pre v-html="rowData.alternative_phone_number"></pre>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Address" ref="addressAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddAddress @success="formSuccess"></AddAddress>
        </modal>

        <modal title="Edit Address" ref="addressEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditAddress :initial-data="editingItem" @success="formSuccess"></EditAddress>
        </modal>

        <delete-modal ref="addressDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Address <b v-html="deletingItem && deletingItem.address"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddAddress from './AddAddress';
import EditAddress from './EditAddress';

export default {
    name       : 'Address',
    components : { AddAddress, EditAddress },
    data () {
        return {
            listUrl      : urls.admin.address.list,
            deleteUrl    : urls.admin.address.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'place',
                    sortField  : 'place',
                    title      : 'Place',
                    titleClass : ''
                },
                {
                    name       : 'address',
                    sortField  : 'address',
                    title      : 'Address',
                    titleClass : ''
                },
                {
                    name       : 'phone_number',
                    sortField  : 'phone_number',
                    title      : 'Phone Number',
                    titleClass : ''
                },
                {
                    name       : '__slot:alternative_phone_number',
                    sortField  : 'alternative_phone_number',
                    title      : 'Alternative Phone Number',
                    titleClass : ''
                },
                {
                    name       : 'email',
                    sortField  : 'email',
                    title      : 'Email',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.addressAddModal.close();
            refs.addressEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.addressEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.addressDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Address..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.addressDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
